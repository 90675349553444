// TODO: Deprecate this component once all users are on Signup v2 already.
import React, { FC, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import {
  Button,
  Context,
  Dropdown,
  Footer,
  getFieldStatus,
  Icon,
  IconType,
  Input,
  PhoneNumberField,
  Toast,
  toast
} from '@athena-labs/web-component-library'
import { DropdownOption } from '@athena-labs/web-component-library/dist/types/types/Dropdown.types'
import { PhoneNumber } from '@athena-labs/web-component-library/dist/types/types/PhoneNumberField.types'
import { AxiosError } from 'axios'
import { Field, FieldProps, Form, Formik } from 'formik'
import { isPersonalEmail } from 'utils/email'
import * as Yup from 'yup'

import Package from '../../../package.json'
import { Analytics } from '../../adapters/Analytics'
import FieldWrapper from '../../components/FieldWrapper'
import Modal from '../../components/Modal'
import { ModalProps } from '../../components/Modal/Modal.types'
import API from '../../helpers/API'
import { arrayToDropdownOptions } from '../../helpers/FormHelper'
import { getClientReferenceId, hasRewardfulReferenceId } from '../../utils/Rewardful'

import { LeadSource, PrefilledSource } from './Signup.types'

import './styles.css'

const COOKIE_NAME = 'portal_accesskey' as const

// This allows us to prefill the "How did you hear about us?" option, hide it and
// instead display a new field specific to the source provided in the query params
const prefilledSourceMap = new Map<string, PrefilledSource>([
  ['yc', {
    label: LeadSource.YCombinator,
    introduction: 'Welcome to Athena! Our EAs are the hidden superpower behind dozens of YC founders, so you\'re in good company. We\'re pumped for the opportunity to work with you.',
    additionalSourceOptions: [
      'Bookface',
      'Word of mouth (YC founder)',
      'Word of mouth (another founder/exec)',
      'Eric Jorgenson\'s podcast with Balaji',
      'Sam Corcos\'s blog posts about Athena',
      'Other'
    ]
  }],
  ['mylance', {
    label: LeadSource.Mylance,
    introduction: 'Welcome to Athena! Our EAs are the hidden superpower behind many Mylance members, so you\'re in good company. We\'re pumped for the opportunity to work with you.',
    additionalSourceOptions: ['Email from Mylance',
      'Mylance website',
      'Another Mylance member',
      'Other']
  }],
  ['referral', {
    label: LeadSource.Referral,
    introduction: 'Welcome to Athena! Our EAs are the hidden superpower behind the friend who referred you, so you\'re in good company. As a VIP, you get to skip the waitlist, so expect to hear from us right away. We\'re pumped for the opportunity to work with you.'
  }],
  ['partner', {
    label: LeadSource.IncentivizedPartnership
  }],
  ['community', {
    label: LeadSource.NonIncentivizedPartnership
  }],
  ['jonathan-vip', {
    label: LeadSource.Outbound,
    introduction: 'Welcome to Athena! As a VIP friend of Jonathan\'s, you get to skip the waitlist, so expect to hear from us right away. We\'re pumped for the opportunity to work with you.',
    code: 'jonathan-network'
  }],
  ['justin-mares', {
    label: LeadSource.Outbound,
    introduction: 'Welcome to Athena! As a VIP friend of Justin\'s, you get to skip the waitlist, so expect to hear from us right away. We\'re pumped for the opportunity to work with you.',
    code: 'justin-mares'
  }],
  ['startup-vip', {
    label: LeadSource.Outbound,
    introduction: 'Welcome to Athena! As a VIP startup founder, you get to skip the waitlist, so expect to hear from us right away. We\'re pumped for the opportunity to work with you.',
    code: 'direct-email-v0'
  }],
  ['rewardful', {
    label: LeadSource.Rewardful
  }],
  ['newsletter', {
    label: LeadSource.Newsletter
  }],
  ['adwords', {
    label: LeadSource.AdWords
  }],
  ['linkedin', {
    label: LeadSource.LinkedIn
  }]
])

const Signup: FC = () => {

  const [loading, setLoading] = useState<boolean>(false)
  // Handle "This expression is not callable" error when
  // setCookie is the only needed object from the destructured array.
  const [, setCookie] = useCookies([COOKIE_NAME])

  // The phone number field has a default country code of US
  const [phoneExtension, setPhoneExtension] = useState('1')

  const [modal, setModal] = useState<ModalProps | null>(null)
  const [referralCode, setReferralCode] = useState<string | undefined>(undefined)

  const sourceOptions: Array<string> = [
    LeadSource.Referral,
    LeadSource.Website,
    LeadSource.Google,
    LeadSource.Twitter,
    LeadSource.LinkedIn,
    LeadSource.Instagram,
    LeadSource.TikTok,
    LeadSource.Facebook,
    LeadSource.BlogPost,
    LeadSource.Podcast,
    LeadSource.YCombinator,
    LeadSource.Mylance,
    LeadSource.Conference,
    LeadSource.Other
  ]

  const queryParams = new URLSearchParams(document.location.search)
  const sourceParam = queryParams.get('source')
  // Remove forward slashes that may be present
  const codeParam = queryParams.get('code')?.replace('/', '')

  const prefilledSource = sourceParam ? prefilledSourceMap.get(sourceParam) : undefined

  useEffect(() => {
    if (prefilledSource?.code) {
      setReferralCode(prefilledSource.code)
    } else if (codeParam) {
      setReferralCode(codeParam)
    } else if (prefilledSource?.label === LeadSource.Rewardful && hasRewardfulReferenceId()) {
      const referenceId = getClientReferenceId()
      setReferralCode(referenceId)
    }
  }, [prefilledSource, codeParam])

  useEffect(() => {
    Analytics.track('Signup started', {
      referralCode: referralCode
    })
  }, [referralCode])

  const isReferrerNameRequired = (source: string) => {
    return [LeadSource.Referral.toString()].includes(source) && !referralCode
  }

  const SignupSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    companyName: Yup.string().required('Company name is required'),
    email: Yup.string().email('Email must be valid')
      .required('Email is required'),
    phoneNumber: Yup.number()
      .typeError('This phone number is invalid. Please try again or check your country')
      .required('Phone number is required'),
    source: Yup.string().required('How did you hear about us is required'),
    referrerName: Yup.string().when('source', (fields, schema) => {
      // Get the field value of source
      const [source] = fields
      if (isReferrerNameRequired(source)) {
        return schema.required('Referrer\'s name is required')
      }
      return schema
    })
  })

  const getStatus = (touched: boolean, error: string | undefined, value: unknown = null) => {
    // Extend getFieldStatus as it is needed to have the checkbox to appear when field values are valid
    if (touched && !error && value) {
      return 'success'
    }
    if (value && !error) {
      return 'success'
    }
    return getFieldStatus(touched, error)
  }

  return (
    <main className='Signup _pageSection'>
      {modal && <Modal {...modal} />}
      <h1 className='Signup_headline'>Start Now</h1>
      <p className='Signup_subheadline'>
        Already started?&nbsp;
        <a href={`${process.env.REACT_APP_PORTAL_URL}/?signup`}>Continue your onboarding</a>.
      </p>

      {prefilledSource?.introduction ? (
        <p className='Signup_subheadline'>{prefilledSource.introduction}</p>
      ) : (
        <p className='Signup_subheadline'>Welcome to Athena! Our EAs are the hidden superpower behind elite founders, top investors, and world-class leaders, so you&apos;re in good company. We&apos;re pumped for the opportunity to work with you.</p>
      )}

      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          phoneNumber: '',
          source: prefilledSource?.label || '',
          additionalSource: '',
          referrerName: '',
          referralCode: referralCode || ''
        }}
        onSubmit={async (values) => {

          try {
            setLoading(true)

            setModal({
              title: 'We\'re creating your account…',
              description: 'In just a few seconds, you can start your onboarding with Athena.',
              icon: 'athena-logo'
            })

            // Blank referrer name for non-referrals
            if (values.source !== LeadSource.Referral) {
              values.referrerName = ''
            }


            const payload = {
              ...values,
              phoneNumber: `+${phoneExtension}${values.phoneNumber}`
            }

            const res = await API.post('/v1/signup', payload)
            const portalAccessKey = res.headers['x-portal-accesskey']

            if (portalAccessKey) {
              Analytics.identify(values.email)
              Analytics.track('Signup successful', {
                referralCode: referralCode
              })

              // Set access key in cookie and redirect to portal,
              // cookie expires in 7 days to match backend
              const inOneWeek = new Date()
              inOneWeek.setDate(inOneWeek.getDate() + 7)
              setCookie(
                COOKIE_NAME,
                portalAccessKey,
                {
                  domain: process.env.NODE_ENV !== 'production' ? 'localhost' : '.athenago.com',
                  secure: true,
                  expires: inOneWeek
                }
              )
              window.location.href = `${process.env.REACT_APP_PORTAL_URL}/onboarding/current`
            } else {
              throw new Error(res.data)
            }
          } catch (error) {
            let message = String(error)
            if (error instanceof AxiosError) {
              message =
                error.response?.data &&
                  error.response.data.message ?
                  error.response.data.message :
                  error.message
            }

            Analytics.track('Signup failed', {
              message
            })
            if (error instanceof AxiosError &&
              error && error.response &&
              error.response.status === 409) {
              setModal({
                title: 'Existing account',
                description: (
                  <>
                    Hey, we know you. You already have an account so all you need to do is&nbsp;
                    <a className='text-accent-orange' href='https://portal.athenago.com/'>sign in</a>
                  </>
                ),
                icon: 'alert-info',
                onClose: () => {
                  setModal(null)
                  setLoading(false)
                }
              })
            } else {
              setModal(null)
              setLoading(false)
              toast(message, 'error')
            }
          }
        }}
        validationSchema={SignupSchema}
      >
        {({ values, isSubmitting, errors, setFieldValue }) => {

          // True for Filipino (+63) or Nigerian (+234) phone numbers
          const showPhoneNumberWarning =
            phoneExtension.indexOf('63') === 0 ||
            phoneExtension.indexOf('234') === 0

          const showSourceWarning = values.source === LeadSource.TikTok

          return (
            <Form>
              <Field name='firstName'>
                {({ field, meta }: FieldProps) => (
                  <FieldWrapper
                    id='firstName'
                    label='First name'
                    touched={meta.touched}
                    error={meta.error}
                  >
                    <Input
                      status={getStatus(meta.touched, meta.error, field.value)}
                      id='firstName'
                      type='text'
                      {...field} />
                  </FieldWrapper>
                )}
              </Field>

              <Field name='lastName'>
                {({ field, meta }: FieldProps) => (
                  <FieldWrapper
                    id='lastName'
                    label='Last name'
                    touched={meta.touched}
                    error={meta.error}
                  >
                    <Input
                      status={getStatus(meta.touched, meta.error, field.value)}
                      id='lastName'
                      type='text'
                      {...field} />
                  </FieldWrapper>
                )}
              </Field>

              <Field name='companyName'>
                {({ field, meta }: FieldProps) => (
                  <FieldWrapper
                    id='companyName'
                    label='Company name'
                    touched={meta.touched}
                    error={meta.error}
                  >
                    <Input
                      status={getStatus(meta.touched, meta.error, field.value)}
                      id='companyName'
                      type='text'
                      {...field} />
                  </FieldWrapper>
                )}
              </Field>

              <Field name='email'>
                {({ field, meta }: FieldProps) => (
                  <FieldWrapper
                    id='email'
                    label='Email'
                    description={meta.touched ? 'Double-check your email is correct' : undefined}
                    touched={meta.touched}
                    error={meta.error}
                  >
                    <Input
                      status={getStatus(meta.touched, meta.error, field.value)}
                      id='email'
                      type='email'
                      {...field} />
                  </FieldWrapper>
                )}
              </Field>

              {isPersonalEmail(values.email) && (
                <div className='EAWarning'>
                  <Icon context={Context.Primary} type={IconType.Alert} />
                  <p className='EAWarning_label'>
                    Are you trying to become an Executive Assistant?&nbsp;
                    <a href='https://jobs.athenago.com' className='EAWarning_redirectLink'>
                      Apply here.
                    </a>
                  </p>
                </div>
              )}

              <Field name='phoneNumber'>
                {({ field, meta }: FieldProps) => (
                  <FieldWrapper
                    id='phoneNumber'
                    label='Phone number'
                    touched={meta.touched}
                    error={meta.error}
                    description='To confirm your profile'
                  >
                    {/* TODO: Fixed behavior of Phone Number Field on WCL */}
                    <PhoneNumberField
                      id='phoneNumber'
                      status={getStatus(meta.touched, meta.error, field.value)}
                      {...field}

                      // We need to use setFieldValue as PhoneNumberInput
                      // doesn't play nicely with Formik
                      onPhoneNumberChange={(value: PhoneNumber) => {
                        setPhoneExtension(value.extension)
                        return setFieldValue('phoneNumber', value.phoneNumber)
                      }}
                    />
                  </FieldWrapper>
                )}
              </Field>

              {showPhoneNumberWarning && (
                <div className='EAWarning'>
                  <Icon context={Context.Primary} type={IconType.Alert} />
                  <p className='EAWarning_label'>
                    Are you trying to become an Executive Assistant?&nbsp;
                    <a href='https://jobs.athenago.com' className='EAWarning_redirectLink'>
                      Apply here.
                    </a>
                  </p>
                </div>
              )}

              <Field name='source'>
                {({ field, meta }: FieldProps) => (
                  <FieldWrapper
                    id='source'
                    label='How did you hear about us?'
                    touched={meta.touched}
                    error={meta.error}
                    // Hide source dropdown when it's prefilled
                    hidden={Boolean(prefilledSource)}
                  >
                    <Dropdown
                      id='source'
                      {...field}
                      options={arrayToDropdownOptions(sourceOptions)}
                      status={getStatus(meta.touched, meta.error, field.value)}
                      name={field.name}
                      placeholder='Select one'
                      // We need to use setFieldValue
                      // as react-select doesn't play nicely with Formik
                      // See https://github.com/jaredpalmer/formik/issues/789
                      onReactSelectChange={(option: DropdownOption) => {
                        void setFieldValue('source', option.value)
                      }}
                      value={
                        arrayToDropdownOptions(sourceOptions)
                          .find((option) => option.value === values.source)
                      }
                    />
                  </FieldWrapper>
                )}
              </Field>

              {showSourceWarning && (
                <div className='EAWarning'>
                  <Icon context={Context.Primary} type={IconType.Alert} />
                  <p className='EAWarning_label'>
                    Are you trying to become an Executive Assistant?&nbsp;
                    <a href='https://jobs.athenago.com' className='EAWarning_redirectLink'>
                      Apply here.
                    </a>
                  </p>
                </div>
              )}

              {prefilledSource?.additionalSourceOptions && (
                <Field name='additionalSource'>
                  {({ field, meta }: FieldProps) => (
                    <FieldWrapper
                      id='additionalSource'
                      label='How did you hear about us?'
                      touched={meta.touched}
                      error={meta.error}
                    >
                      <Dropdown
                        id='additionalSource'
                        {...field}
                        options={
                          // For some reason TypeScript thinks additionalSourceOptions can be
                          // undefined despite the check at the start of the JSX block
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          arrayToDropdownOptions(prefilledSource.additionalSourceOptions!)
                        }
                        status={getStatus(meta.touched, meta.error, field.value)}
                        name={field.name}
                        placeholder='Select one'
                        // We need to use setFieldValue
                        // as react-select doesn't play nicely with Formik
                        // See https://github.com/jaredpalmer/formik/issues/789
                        onReactSelectChange={(option: DropdownOption) => void setFieldValue('additionalSource', option.value)}
                        value={
                          // For some reason TypeScript thinks additionalSourceOptions can be
                          // undefined despite the check at the start of the JSX block
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          arrayToDropdownOptions(prefilledSource.additionalSourceOptions!)
                            .find((option) => option.value === values.additionalSource)
                        }
                      />
                    </FieldWrapper>
                  )}
                </Field>
              )}

              {(isReferrerNameRequired(values.source)) && (
                <>
                  <Field name='referrerName'>
                    {({ field, meta }: FieldProps) => (
                      <FieldWrapper
                        id='referrerName'
                        label="Referrer&apos;s name (first and last)"
                        touched={meta.touched}
                        error={meta.error}
                      >
                        <Input
                          status={getStatus(meta.touched, meta.error, field.value)}
                          id='referrerName'
                          type='text'
                          {...field} />
                      </FieldWrapper>
                    )}
                  </Field>
                </>
              )}

              {Boolean(referralCode) && (
                <>
                  <Field name='referralCode'>
                    {({ field, meta }: FieldProps) => (
                      <FieldWrapper
                        id='referralCode'
                        label='Referral Code'
                        touched={meta.touched}
                        error={meta.error}
                        // Set this to be hidden as it's filled from the query param
                        hidden={true}
                      >
                        <Input
                          status={getStatus(meta.touched, meta.error, field.value)}
                          id='referralCode'
                          type='text'
                          {...field} />
                      </FieldWrapper>
                    )}
                  </Field>
                </>
              )}

              <div className='Signup_notice'>
                <p className='Signup_noticeHeading'>
                  I agree to receive emails from Athena
                </p>
                <p className='Signup_noticeSubheading'>
                  (but soon, you won&apos;t worry about emails because you&apos;ll have an EA).
                </p>
              </div>
              <br />

              <div className='Signup_submit'>
                <Button
                  type='submit'
                  color='primary'
                  isLoading={isSubmitting}
                  disabled={isSubmitting || loading || Boolean(Object.keys(errors).length)}>
                  Partner with an EA
                </Button>
              </div>

              {!sourceParam && <p className='Signup_subheadline'>
                Interested in becoming an Executive Assistant?&nbsp;
                <a href='https://jobs.athenago.com/executive-partner'>Apply here</a>.
              </p>}
            </Form>

          )
        }}
      </Formik>
      <Toast />
      <Footer version={Package.version} />
    </main>
  )
}
export default Signup