import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router'
import { toast } from '@athena-labs/web-component-library'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Analytics } from 'adapters/Analytics'
import { ACCESS_KEY, getSignup, patchSignup, postSignup } from 'adapters/API.v2'
import { AxiosError } from 'axios'
import { DEFAULT_ERROR_MESSAGE } from 'constants/toast-messages'
import { useLeadSourceStore } from 'stores/LeadSource'
import { useSignupStore } from 'stores/SignupStore'
import { SignupPatchRequestParams, SignupStep } from 'types/Signup.types'
import { getSignupPath } from 'utils/signup'

export const SIGNUP_QUERY_KEY = 'signup' as const
export const LOCAL_STEP_KEY = 'localStep'

export const useSignup = (accessKey?: string) => {

  const { journey } = useLeadSourceStore()

  // Instead of relying on react-query's `isLoading`, which returns `true` when disabled,
  // we'll manage the loading state locally.
  const [isLoading, setIsLoading] = useState(false)

  const [, setCookie] = useCookies([ACCESS_KEY])
  const navigate = useNavigate()

  const queryKey = [SIGNUP_QUERY_KEY, accessKey]
  const { setCurrentStep } = useSignupStore()

  const handleUnknownError = (error: AxiosError) => {
    setIsLoading(false)

    // Track unhandled errors for analysis
    Analytics.track('Unhandled error', {
      error: error
    })
    toast(DEFAULT_ERROR_MESSAGE, 'error')
  }

  // Fetches the current signup step upon page load.
  const {
    data: signup,
    isError,
    error,
    refetch
  } = useQuery({
    queryKey,
    // Non-null assertion is used here as the queryFn will only be invoked
    // when accessKey is available.
    // The enabled property below is set to true only when accessKey exist,
    // ensuring that the arguments for queryFn are actually non-null when the function is called.
    queryFn: () => getSignup(accessKey!),
    refetchOnWindowFocus: false,
    enabled: Boolean(accessKey),
    retry: 0
  })

  useEffect(() => {
    if (accessKey) {
      return setIsLoading(true)
    }
    setIsLoading(false)
  }, [accessKey])

  const { mutateAsync: createSignup, isPending: isCreating } = useMutation({
    mutationFn: postSignup,
    onError: (error: AxiosError) => {
      Analytics.track('Signup failed', {
        error: error
      })
      handleUnknownError(error)
    },
    onSuccess: async (data) => {
      const { accessKey, signup } = data

      const inTwoDays = new Date()
      inTwoDays.setDate(inTwoDays.getDate() + 2)

      if (accessKey) {
        setCookie(
          ACCESS_KEY,
          accessKey,
          {
            path: '/',
            domain: process.env.NODE_ENV !== 'production' ? 'localhost' : '.athenago.com',
            secure: true,
            expires: inTwoDays
          }
        )
      }

      if (signup && accessKey) {
        Analytics.track('Signup successful', {
          journey
        })
        setCurrentStep(signup.step)
        navigate(getSignupPath(signup.step))
      } else {
        toast(DEFAULT_ERROR_MESSAGE, 'error')
      }
    }
  })

  const { mutateAsync: updateSignup, isPending: isUpdating } = useMutation({
    mutationFn: (param: SignupPatchRequestParams) => {
      return patchSignup(param)
    },
    onError: handleUnknownError,
    onSuccess: async (signup, params) => {
      let currentStep = signup.step
      const previousStep = params.step
      // When processing the patch request response, we track the user's progression through the signup steps.
      // Notably, if the signup step returned is "Commitments", a slight deviation occurs:
      // Instead of immediately showing the Commitments step, we display the "Getting Started (Pillars Overview)" screen first.
      // To ensure continuity and remember user transitions, we use local storage to track when the user navigates past this introductory screen.
      if (currentStep === SignupStep.Commitments) {
        localStorage.setItem(LOCAL_STEP_KEY, SignupStep.GettingStarted)
        currentStep = SignupStep.GettingStarted
      }

      Analytics.track('Signup updated', {
        journey,
        step: previousStep
      })

      setCurrentStep(currentStep)
      navigate(getSignupPath(currentStep))
    }
  })

  return {
    signup,
    createSignup,
    updateSignup,
    isLoading,
    isMutating: isCreating || isUpdating,
    isError,
    error,
    refetch
  }
}