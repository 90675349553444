import React from 'react'
import { InlineWidget } from 'react-calendly'
import { SignupStep } from 'types/Signup.types'

import Waiting from '../components/Waiting'

const overrideSchedulingUrl = process.env.REACT_APP_OVERRIDE_SCHEDULING_URL
const schedulingUrl = overrideSchedulingUrl ?
  overrideSchedulingUrl :
  'https://calendly.com/d/yh7-g78-mf7/athena-two-way-fit-call'

const Waitlist: React.FC = () => {
  return (
    <Waiting step={SignupStep.Waitlist}>
      <InlineWidget
        url={schedulingUrl}
        pageSettings={{
          backgroundColor: '1A1A2C', // shade-100
          primaryColor: '91C3BE', // accent-blue darkened by 5%.
          textColor: 'F4F0F3' // primary-white
        }}
        styles={{
          height: '1100px'
        }}
      />
    </Waiting>
  )
}

export default Waitlist