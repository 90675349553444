import React from 'react'

type HexagonGlowPosition = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right'

type HexagonProps = {
  position?: HexagonGlowPosition
}

type SVGProps = {
  d: string
  x: number
  y: number
  x1: number
  x2: number
  y1: number
  y2: number
  width: number
  height: number
}

const svgProps: Record<HexagonGlowPosition, SVGProps> = {
  'top-left': {
    d: 'm16.063 114.95 40.197 23.395 48.189-82.798-40.196-23.395-48.19 82.798Z',
    x: 9.063,
    y: 25.152,
    x1: 80.162,
    x2: 39.965,
    y1: 97.277,
    y2: 73.882,
    width: 102.386,
    height: 120.193
  },
  'top-center': {
    d: 'M102.139 8.483v46.51h95.8V8.482h-95.8Z',
    x: 95.139,
    y: 1.483,
    x1: 149.656,
    x2: 149.656,
    y1: 54.992,
    y2: 8.483,
    width: 109.8,
    height: 60.509
  },
  'top-right': {
    d: 'm239.488 31.407-39.37 24.761 51.003 81.095 39.37-24.76-51.003-81.096Z',
    width: 104.373,
    height: 119.856,
    x: 193.118,
    y: 24.407,
    x1: 225.415,
    x2: 264.785,
    y1: 96.392,
    y2: 71.63
  },

  'bottom-left': {
    d: 'm64.846 246.001 39.426-24.672-50.82-81.21-39.426 24.672 50.82 81.21Z',
    width: 104.245,
    height: 119.882,
    x: 7.026,
    y: 133.119,
    x1: 79.065,
    x2: 39.639,
    y1: 181.049,
    y2: 205.721
  },
  'bottom-center': {
    d: 'M201.939 268.992v-46.509h-95.8v46.509h95.8Z',
    x: 99.139,
    y: 215.483,
    x1: 154.422,
    x2: 154.422,
    y1: 222.483,
    y2: 268.992,
    width: 109.8,
    height: 60.509
  },
  'bottom-right': {
    d: 'm291.545 161.289-40.391-23.059-47.497 83.197 40.391 23.059 47.497-83.197Z',
    width: 101.888,
    height: 120.256,
    x: 196.657,
    y: 131.23,
    x1: 227.595,
    x2: 267.986,
    y1: 179.496,
    y2: 202.555
  }
}

const Hexagon: React.FC<HexagonProps> = ({ position }) => {
  const svg = position && svgProps[position]

  return (
    <figure>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={305}
        height={277}
        viewBox='0 0 302 277'
        fill='none'
      >
        {
          svg && (
            <g filter='url(#a)' opacity={0.9}>
              <path fill='url(#b)' d={svg.d} />
            </g>
          )
        }
        <g filter='url(#c)'>
          <path
            fill='url(#d)'
            d='m186.043 79.472 17.978 29.41 17.506 29.68-16.615 29.65-17.096 29.379-34.594.24-34.602-.302-17.979-29.409-17.506-29.681 16.616-29.65 17.096-29.379 34.594-.24 34.602.302Z'
          />
          <path
            stroke='#9ED0CB'
            d='m186.043 79.472 17.978 29.41 17.506 29.68-16.615 29.65-17.096 29.379-34.594.24-34.602-.302-17.979-29.409-17.506-29.681 16.616-29.65 17.096-29.379 34.594-.24 34.602.302Z'
          />
        </g>
        <g filter='url(#e)' opacity={0.4}>
          <path
            fill='url(#f)'
            d='m186.043 79.472 17.978 29.41 17.506 29.68-16.615 29.65-17.096 29.379-34.594.24-34.602-.302-17.979-29.409-17.506-29.681 16.616-29.65 17.096-29.379 34.594-.24 34.602.302Z'
          />
        </g>
        <g filter='url(#g)'>
          <path
            stroke='#9ED0CB'
            strokeWidth={1.5}
            d='m151.2 55.003 48.631.36 25.212 41.367.006.01 24.542 41.738-23.4 41.754-.006.01-24.074 41.373-48.609.403h-.012l-48.63-.361-25.212-41.366-.006-.01L55.1 138.543l23.4-41.754.005-.01 24.074-41.373 48.615-.403h.006Z'
          />
        </g>
        <defs>
          {
            svg && (
              <filter
                id='a'
                width={svg.width}
                height={svg.height}
                x={svg.x}
                y={svg.y}
                colorInterpolationFilters='sRGB'
                filterUnits='userSpaceOnUse'
              >
                <feFlood floodOpacity={0} result='BackgroundImageFix' />
                <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                <feGaussianBlur
                  result='effect1_foregroundBlur_1_24'
                  stdDeviation={3.5}
                />
              </filter>
            )
          }
          <filter
            id='c'
            width={229.545}
            height={209.661}
            x={37.559}
            y={33.67}
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset />
            <feGaussianBlur stdDeviation={22.5} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix values='0 0 0 0 0.392157 0 0 0 0 0.721569 0 0 0 0 0.803922 0 0 0 1 0' />
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1_24' />
            <feBlend
              in='SourceGraphic'
              in2='effect1_dropShadow_1_24'
              result='shape'
            />
          </filter>
          <filter
            id='e'
            width={228.392}
            height={208.661}
            x={38.135}
            y={34.17}
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset />
            <feGaussianBlur stdDeviation={22.5} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix values='0 0 0 0 0.392157 0 0 0 0 0.721569 0 0 0 0 0.803922 0 0 0 1 0' />
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1_24' />
            <feBlend
              in='SourceGraphic'
              in2='effect1_dropShadow_1_24'
              result='shape'
            />
          </filter>
          <filter
            id='g'
            width={304.221}
            height={276.515}
            x={0.235}
            y={0.253}
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset />
            <feGaussianBlur stdDeviation={27} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix values='0 0 0 0 0.392157 0 0 0 0 0.721569 0 0 0 0 0.803922 0 0 0 1 0' />
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1_24' />
            <feBlend
              in='SourceGraphic'
              in2='effect1_dropShadow_1_24'
              result='shape'
            />
          </filter>
          {
            svg && (
              <linearGradient
                id='b'
                x1={svg.x1}
                x2={svg.x2}
                y1={svg.y1}
                y2={svg.y2}
                gradientUnits='userSpaceOnUse'
              >
                <stop offset={0.115} stopColor='#9ED0CB' stopOpacity={0.63} />
                <stop offset={0.901} stopOpacity={0} />
              </linearGradient>
            )
          }
          <linearGradient
            id='d'
            x1={92.164}
            x2={212.724}
            y1={103.877}
            y2={172.729}
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#9ED0CB' />
            <stop offset={1} stopColor='#BFD8D5' />
          </linearGradient>
          <linearGradient
            id='f'
            x1={92.164}
            x2={212.724}
            y1={103.877}
            y2={172.729}
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#9ED0CB' />
            <stop offset={1} stopColor='#BFD8D5' />
          </linearGradient>
        </defs>
      </svg>
    </figure>
  )
}

export default Hexagon