import React from 'react'
import { useCookies } from 'react-cookie'
import { Context, Dropdown, FormField, getFieldStatus, Icon, IconType, Input } from '@athena-labs/web-component-library'
import { DropdownOption } from '@athena-labs/web-component-library/dist/types/types/Dropdown.types'
import { ACCESS_KEY } from 'adapters/API.v2'
import { Field, FieldProps, Formik } from 'formik'
import { arrayToDropdownOptions } from 'helpers/FormHelper'
import { useSignup } from 'hooks/useSignup'
import { observer } from 'mobx-react-lite'
import { useLeadSourceStore } from 'stores/LeadSource'
import { LeadSource, LeadSourceCampaign } from 'types/LeadSource.types'
import { JoinWaitlistPayloadSchema, SignupStep } from 'types/Signup.types'
import { trackFieldValue } from 'utils/input'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import SubmitButton from '../components/SubmitButton'

const SOURCE_OPTIONS: Array<string> = [
  LeadSource.Referral,
  LeadSource.Website,
  LeadSource.Google,
  LeadSource.BlogPost,
  LeadSource.Podcast,
  LeadSource.Conference,
  LeadSource.Other
]

const CAMPAIGN_OPTIONS: Array<string> = [
  LeadSourceCampaign.Twitter,
  LeadSourceCampaign.LinkedIn,
  LeadSourceCampaign.Instagram,
  LeadSourceCampaign.TikTok,
  LeadSourceCampaign.Facebook,
  LeadSourceCampaign.YCombinator,
  LeadSourceCampaign.Mylance
]

const SOCIAL_CAMPAIGNS = [
  LeadSourceCampaign.Facebook,
  LeadSourceCampaign.Instagram,
  LeadSourceCampaign.LinkedIn,
  LeadSourceCampaign.TikTok,
  LeadSourceCampaign.Twitter
]

const SignupForm: React.FC = observer(() => {

  const { updateSignup } = useSignup()
  const [cookies] = useCookies([ACCESS_KEY])
  const { signup_accesskey } = cookies

  const sourceFieldOptions = [
    ...SOURCE_OPTIONS,
    ...CAMPAIGN_OPTIONS
  ]

  const leadSourceStore = useLeadSourceStore()
  const {
    source: leadSource,
    campaign: leadSourceCampaign,
    content: leadSourceContent,
    viewModel
  } = leadSourceStore

  const isReferrerNameRequired = (source: string) => {
    return source === LeadSource.Referral && !leadSourceContent
  }

  // TODO: Move this to WLC
  const getStatus = (touched: boolean, error: string | undefined, value: unknown = null) => {
    if (touched && !error && value) {
      return 'success'
    }
    if (value && !error) {
      return 'success'
    }
    return getFieldStatus(touched, error)
  }

  const onSubmit = async (values: z.infer<typeof JoinWaitlistPayloadSchema>) => {
    await updateSignup({
      step: SignupStep.JoinWaitlist,
      accessKey: signup_accesskey,
      payload: JoinWaitlistPayloadSchema.parse(values)
    })
  }

  return (
    <Formik
      initialValues={{
        leadSource,
        leadSourceCampaign,
        leadSourceContent
      }}
      onSubmit={onSubmit}
      validationSchema={toFormikValidationSchema(JoinWaitlistPayloadSchema)}
    >
      {({ values, setFieldValue, isSubmitting, errors, submitForm }) => {
        const showSourceWarning = values.leadSourceCampaign === LeadSourceCampaign.TikTok
        return (
          <section className='_section'>
            <h1 className='_textHeading'>Join the Waitlist</h1>
            <p className='_textSubheading'>
              Choose a date to kick things off.
              We&apos;ll add you to our waitlist and figure out the best time to begin.
            </p>

            {viewModel?.introduction && (
              <p className='Signup_subheadline'>{viewModel.introduction}</p>
            )}

            <div className='_fieldContainer'>
              <Field name='leadSource'>
                {({ field, meta }: FieldProps) => (
                  <FormField
                    id='leadSource'
                    label='How did you hear about us?'
                    touched={meta.touched}
                    errorHint={meta.error}
                    // Hide leadSource dropdown when it's prefilled
                    hidden={Boolean(leadSource)}
                  >
                    <Dropdown
                      id='leadSource'
                      {...field}
                      options={arrayToDropdownOptions(sourceFieldOptions)}
                      status={getStatus(meta.touched, meta.error, field.value)}
                      name={field.name}
                      placeholder='Select one'
                      // We use setFieldValue as react-select doesn't play nicely with Formik
                      // See https://github.com/jaredpalmer/formik/issues/789
                      onReactSelectChange={async (option: DropdownOption) => {
                        const selectedOption = option.value

                        // Set lead source campaign to Referral v0 when Referral is selected.
                        if (selectedOption === LeadSource.Referral) {
                          await setFieldValue('leadSourceCampaign', LeadSourceCampaign.ReferralV0)
                        }

                        // Set Social lead source properties.
                        if (SOCIAL_CAMPAIGNS.includes(selectedOption as LeadSourceCampaign)) {
                          await setFieldValue('leadSource', LeadSource.Social).then()
                          await setFieldValue('leadSourceCampaign', selectedOption)
                          return
                        }

                        // Set the lead source properties when 'Mylance' is selected.
                        if (selectedOption === LeadSourceCampaign.Mylance) {
                          await setFieldValue('leadSource', LeadSource.IncentivizedPartnership)
                          await setFieldValue('leadSourceCampaign', selectedOption)
                          return
                        }

                        // Set the lead source properties when 'Y Combinator' is selected.
                        if (selectedOption === LeadSourceCampaign.YCombinator) {
                          void setFieldValue('leadSource', LeadSource.NonIncentivizedPartnership)
                          void setFieldValue('leadSourceCampaign', selectedOption)
                          return
                        }
                        void setFieldValue('leadSource', selectedOption)
                      }}
                      value={
                        arrayToDropdownOptions(sourceFieldOptions)
                          .find((option) => option.value === values.leadSource)
                      }
                      onBlur={trackFieldValue(field.name)}
                    />
                  </FormField>
                )}
              </Field>
              {showSourceWarning && (
                <div className='EAWarning'>
                  <Icon context={Context.Primary} type={IconType.Alert} />
                  <p className='EAWarning_label'>
                    Are you trying to become an Executive Assistant?&nbsp;
                    <a href='https://jobs.athenago.com' className='EAWarning_redirectLink'>
                      Apply here.
                    </a>
                  </p>
                </div>
              )}

              {viewModel?.contentOptions && (
                <Field name='leadSourceContent'>
                  {({ field, meta }: FieldProps) => (
                    <FormField
                      id='leadSourceContent'
                      label='How did you hear about us?'
                      touched={meta.touched}
                      errorHint={meta.error}
                    >
                      <Dropdown
                        id='leadSourceContent'
                        {...field}
                        options={
                          // For some reason TypeScript thinks contentOptions can be
                          // undefined despite the check at the start of the JSX block
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          arrayToDropdownOptions(viewModel.contentOptions!)
                        }
                        status={getStatus(meta.touched, meta.error, field.value)}
                        name={field.name}
                        placeholder='Select one'
                        // We use setFieldValue as react-select doesn't play nicely with Formik
                        // See https://github.com/jaredpalmer/formik/issues/789
                        onReactSelectChange={(option: DropdownOption) => setFieldValue('leadSourceContent', option.value)}
                        value={
                          // For some reason TypeScript thinks contentOptions can be
                          // undefined despite the check at the start of the JSX block
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          arrayToDropdownOptions(viewModel.contentOptions!)
                            .find((option) => option.value === values.leadSourceContent)
                        }
                        onBlur={trackFieldValue(field.name)}
                      />
                    </FormField>
                  )}
                </Field>
              )}

              {(isReferrerNameRequired(values.leadSource)) && (
                <Field name='referrerName'>
                  {({ field, meta }: FieldProps) => (
                    <FormField
                      id='referrerName'
                      label="Referrer&apos;s name (first and last)"
                      touched={meta.touched}
                      errorHint={meta.error}
                    >
                      <Input
                        {...field}
                        status={getStatus(meta.touched, meta.error, field.value)}
                        id='referrerName'
                        type='text'
                        onBlur={trackFieldValue(field.name)} />
                    </FormField>
                  )}
                </Field>
              )}
            </div>
            <SubmitButton
              onSubmit={submitForm}
              loading={isSubmitting}
              disabled={isSubmitting || Boolean(Object.keys(errors).length)}
            />
          </section >
        )
      }}
    </Formik>
  )
})

export default SignupForm