import React from 'react'
import { IconType } from '@athena-labs/web-component-library'
import { SignupStep } from 'types/Signup.types'

import Banner from 'components/Banner'

import Waiting from '../components/Waiting'

const FAQS_URL = 'https://www.athenago.com/faqs'

const banner = {
  callToAction: 'Have questions?',
  icon: IconType.Help,
  buttonText: 'Read our FAQs',
  onClick: () => window.open(FAQS_URL, '_blank')
}

const WaitingTwoWayFit: React.FC = () => {
  return (
    <Waiting step={SignupStep.WaitingTwoWayFit}>
      <Banner
        callToAction={banner.callToAction}
        icon={banner.icon}
        buttonText={banner.buttonText}
        onClick={banner.onClick}
        external={true}
      />
    </Waiting>
  )
}

export default WaitingTwoWayFit