import React, { FC } from 'react'
import { Checkbox, Context, Icon } from '@athena-labs/web-component-library'
import { CommitmentsButtonsProps } from 'types/Commitments.types'

import styles from './CommitmentsButton.module.css'

const CommitmentsButton: FC<CommitmentsButtonsProps> = ({
  checked,
  title,
  description,
  icon,
  commitment,
  onClick
}) => {

  return (
    <div
      className={[
        styles.CommitmentsButton_wrapper,
        checked && styles.CommitmentsButton_wrapper___active
      ].join(' ')}>
      <button
        className={styles.CommitmentsButton}
        type='button'
        onClick={() => onClick(commitment)}
      >
        <div className={styles.CommitmentsButton_heading}>
          <aside className={styles.CommitmentsButton_headingIcon}>
            <Icon
              type={icon}
              context={checked ? Context.Active : Context.Secondary} />
          </aside>
          <p className={styles.CommitmentsButton_headingTitle}>{title}</p>
        </div>
        <p className={styles.CommitmentsButton_description}>{description}</p>
        <div className={styles.CommitmentsButton_checkbox}>
          <Checkbox checked={checked} />
        </div>
      </button>
    </div>
  )
}

export default CommitmentsButton