import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { IconType } from '@athena-labs/web-component-library'
import { ACCESS_KEY } from 'adapters/API.v2'
import { useSignup } from 'hooks/useSignup'
import { Commitment, CommitmentDetails } from 'types/Commitments.types'
import { SignupStep } from 'types/Signup.types'

import SubmitButton from '../components/SubmitButton'

import CommitmentsButton from './components/CommitmentsButton'

import styles from './Commitments.module.css'

const COMMITMENT_DESCRIPTION = new Map<Commitment, CommitmentDetails>([
  [Commitment.Fulltime, {
    title: '1:1 Full-Time Partnership',
    description: 'My EA works for me 40 hours per week to maximize my individual leverage and help me live a better life.',
    icon: IconType.Globe
  }],
  [Commitment.TimeInvestment, {
    title: 'Time Investment',
    description: 'I will invest time upfront with my EA and the Athena team so I ultimately get back more time and energy to reallocate to my goals.',
    icon: IconType.Calendar
  }],
  [Commitment.CulturalLearning, {
    title: 'Cultural Learning',
    description: 'I will respect that my EA is a global partner from another culture with different experiences and norms.',
    icon: IconType.Globe
  }],
  [Commitment.MentorshipDevelopment, {
    title: 'Mentorship & Development',
    description: 'I expect my EA to learn and adapt, but to accelerate their growth, I will participate in their development.',
    icon: IconType.Rocket
  }]
])

const NUMBER_OF_COMMITMENTS = 4

const Commitments: React.FC = () => {

  const [commitments, setCommitments] = useState<Array<Commitment>>([])

  const { updateSignup, isMutating } = useSignup()
  const [cookies] = useCookies([ACCESS_KEY])
  const { signup_accesskey } = cookies


  const onClick = (newCommitment: Commitment) => {
    setCommitments(prev => {
      if (!prev.includes(newCommitment)) {
        return [...commitments, newCommitment]
      } else {
        return commitments.filter(commitment => commitment !== newCommitment)
      }
    })
  }

  const isChecked = (commitment: Commitment) => {
    return commitments.includes(commitment)
  }


  const onSubmit = async () => {
    await updateSignup({
      step: SignupStep.Commitments,
      accessKey: signup_accesskey
    })
  }

  return (
    <>
      <section className='_section'>
        <h1 className='_textHeading'>Four Commitments</h1>
        <p className='_textSubheading'>
          As a member of Athena, I commit to the following:
        </p>
        <div className='_fieldContainer'>
          <div className={`${styles.Commitments_buttonGroup}`}>
            {
              Array.from(COMMITMENT_DESCRIPTION).map(commitmentMap => {
                const [commitment, detail] = commitmentMap
                const { title, description, icon } = detail
                return (
                  <CommitmentsButton
                    commitment={commitment}
                    title={title}
                    description={description}
                    icon={icon}
                    checked={isChecked(commitment)}
                    onClick={onClick}
                    key={commitment}
                  />
                )
              })
            }
          </div>
        </div>
        <SubmitButton
          onSubmit={onSubmit}
          loading={isMutating}
          disabled={isMutating || commitments.length !== NUMBER_OF_COMMITMENTS}
        />
      </section>
    </>
  )
}

export default Commitments