import React, { FC } from 'react'
import { IconLegacy, useMediaQuery } from '@athena-labs/web-component-library'

import { TooltipProps } from './Tooltip.types'

import './styles.css'

const Tooltip: FC<TooltipProps> = ({ label, content, position }) => {
  const { isDesktop } = useMediaQuery({ updateOnResize: true })
  return (
    <div className={`Tooltip Tooltip___${isDesktop ? position || 'top' : 'bottom'}`}>
      {label && <span className='Tooltip_label'>{label}</span>}
      <div className='Tooltip_icon'>
        <IconLegacy type='question' color='secondary' />
      </div>
      <span className='Tooltip_content'>{content}</span>
    </div>
  )
}

export default Tooltip