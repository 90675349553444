import React from 'react'
import { IconLegacy } from '@athena-labs/web-component-library'

import hourglassSvg from '../../assets/hourglass.svg'

import { ModalProps } from './Modal.types'

import './styles.css'

const Modal: React.FC<ModalProps> = ({ title, description, onClose, icon }) => {
  return (
    <div className='Signup_cover'>
      <div className='Signup_modal'>
        {
          onClose && <button type='button' onClick={onClose} className='Signup_modalClose'>
            <IconLegacy color='secondary' type='alert-error' />
          </button>
        }
        <div className='Signup_modalIcon'>
          {
            icon === 'athena-logo' ? (
              //  TODO: Animate icon and move to WCL
              <img className='Signup_modalIcon' src={hourglassSvg} alt='Athena Logo' />
            ) : (
              <IconLegacy color='danger' type={icon} />
            )
          }
        </div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default Modal