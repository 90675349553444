import { IconType } from '@athena-labs/web-component-library'

export enum Commitment {
  Fulltime = 'fulltime',
  TimeInvestment = 'timeInvestment',
  CulturalLearning = 'culturalLearning',
  MentorshipDevelopment = 'mentorshipDevelopment'
}

export type CommitmentDetails = {
  title: string
  description: string
  icon: IconType
}


export type CommitmentsButtonsProps = {
  checked: boolean
  title: string
  description: string
  icon: IconType
  commitment: Commitment
  onClick: (type: Commitment) => void
}