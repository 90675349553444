import React from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from 'stores/Auth'
import { LeadSourceProvider } from 'stores/LeadSource'
import { SignupStoreProvider } from 'stores/SignupStore'

import { Analytics } from './adapters/Analytics'
import AppRoutes from './AppRoutes'

Analytics.init()

function App() {
  return (
    <LeadSourceProvider>
      <SignupStoreProvider>
        <AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <AppRoutes />
        </AuthProvider>
      </SignupStoreProvider>
    </LeadSourceProvider>
  )
}

export default App
