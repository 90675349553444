import React, { PropsWithChildren } from 'react'
import { makeAutoObservable } from 'mobx'
import {
  LeadSource,
  LeadSourceCampaign,
  LeadSourceProps,
  LeadSourceURLSearchParams,
  LeadSourceURLSearchParamValue,
  LeadSourceViewModel
} from 'types/LeadSource.types'
import { getClientReferenceId, hasRewardfulReferenceId } from 'utils/Rewardful'

export class LeadSourceStore {
  campaign = ''
  content = ''
  source = ''
  viewModel: LeadSourceViewModel | undefined
  _journey = 'default'
  _urlSearchParams: LeadSourceURLSearchParams

  constructor() {
    // Initialize the URL Search Params Proxy
    this._urlSearchParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => {
        if (typeof prop === 'string') {
          return searchParams.get(prop as string)
        }
      }
    }) as LeadSourceURLSearchParams

    // Load lead source properties from the `source` query parameter.
    this.loadSourceQueryParameter()

    // Load the lead source content from the `code` query parameter.
    this.loadCodeQueryParameter()

    // Load lead source properties from UTM parameters.
    this.loadUtmParameters()

    makeAutoObservable(this)
  }

  /**
   * @deprecated
   * We're moving to use the UTM parameters to relay lead source properties.
   * Let's remove this once we're fully settled into our UTM param tracking for lead source properties.
   */
  private loadCodeQueryParameter() {
    if (this.campaign === LeadSourceCampaign.Rewardful && hasRewardfulReferenceId()) {
      this.content = getClientReferenceId()
    }

    const code = this._urlSearchParams.code
    if (code) {
      this.content = code
    }
  }

  /**
   * @deprecated
   * We're moving to use the UTM parameters to relay lead source properties.
   */
  private loadSourceQueryParameter() {
    const sourceParam = this._urlSearchParams.source

    // Validate the `source` URL search param.
    const leadSourceProps = SOURCE_LEAD_URL_PARAM_PROPS_MAP.get(sourceParam)
    if (!leadSourceProps) {
      return
    }

    const { source, campaign, content, viewModel } = leadSourceProps
    this.source = source
    this.campaign = campaign
    this.content = content
    this.viewModel = viewModel
  }

  private loadUtmParameters() {
    const utmSource = this._urlSearchParams.utm_source
    const utmCampaign = this._urlSearchParams.utm_campaign
    const utmContent = this._urlSearchParams.utm_content

    if (utmSource) {
      this.source = utmSource
    }

    if (utmCampaign) {
      this.campaign = utmCampaign
    }

    if (utmContent) {
      this.content = utmContent
    }
  }

  public get journey() {
    const journey = this._urlSearchParams.journey
    if (journey) {
      this._journey = journey
    }
    return this._journey
  }
}

const LeadSourceContext = React.createContext<LeadSourceStore | undefined>(undefined)

export const LeadSourceProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <LeadSourceContext.Provider value={new LeadSourceStore()}>
      {children}
    </LeadSourceContext.Provider>
  )
}

export const useLeadSourceStore = () => {
  const context = React.useContext(LeadSourceContext)
  if (context === undefined) {
    throw new Error('useLeadSourceStore must be used within an LeadSourceProvider')
  }
  return context
}

// Note: This is the single source of truth of how `/?source` URL param is mapped to our lead source properties.
// Use this map to get lead source properties from a given source URL parameter.
const SOURCE_LEAD_URL_PARAM_PROPS_MAP = new Map<LeadSourceURLSearchParamValue, LeadSourceProps>([
  [LeadSourceURLSearchParamValue.AdWords, {
    source: LeadSource.Google,
    campaign: LeadSourceCampaign.AdWords,
    content: ''
  }],
  [LeadSourceURLSearchParamValue.Community, {
    source: LeadSource.NonIncentivizedPartnership,
    campaign: '',
    content: ''
  }],
  [LeadSourceURLSearchParamValue.JonathanNetwork, {
    source: LeadSource.Outbound,
    campaign: '',
    content: 'jonathan-network',
    viewModel: {
      introduction: 'Welcome to Athena! As a VIP friend of Jonathan\'s, you get to skip the waitlist, so expect to hear from us right away. We\'re pumped for the opportunity to work with you.'
    }
  }],
  [LeadSourceURLSearchParamValue.JustinMares, {
    source: LeadSource.Outbound,
    campaign: '',
    content: 'justin-mares',
    viewModel: {
      introduction: 'Welcome to Athena! As a VIP friend of Justin\'s, you get to skip the waitlist, so expect to hear from us right away. We\'re pumped for the opportunity to work with you.'
    }
  }],
  [LeadSourceURLSearchParamValue.Mylance, {
    source: LeadSource.IncentivizedPartnership,
    campaign: LeadSourceCampaign.Mylance,
    content: '',
    viewModel: {
      introduction: 'Welcome to Athena! Our EAs are the hidden superpower behind many Mylance members, so you\'re in good company. We\'re pumped for the opportunity to work with you.',
      contentOptions: [
        'Email from Mylance',
        'Mylance website',
        'Another Mylance member',
        'Other'
      ]
    }
  }],
  [LeadSourceURLSearchParamValue.Newsletter, {
    source: LeadSource.NonIncentivizedPartnership,
    campaign: LeadSourceCampaign.Newsletter,
    content: ''
  }],
  [LeadSourceURLSearchParamValue.Partner, {
    source: LeadSource.IncentivizedPartnership,
    campaign: '',
    content: ''
  }],
  [LeadSourceURLSearchParamValue.Referral, {
    source: LeadSource.Referral,
    campaign: '',
    content: '',
    viewModel: {
      introduction: 'Welcome to Athena! Our EAs are the hidden superpower behind the friend who referred you, so you\'re in good company. As a VIP, you get to skip the waitlist, so expect to hear from us right away. We\'re pumped for the opportunity to work with you.'
    }
  }],
  [LeadSourceURLSearchParamValue.Rewardful, {
    source: LeadSource.IncentivizedPartnership,
    campaign: LeadSourceCampaign.Rewardful,
    content: ''
  }],
  [LeadSourceURLSearchParamValue.StartupVip, {
    source: LeadSource.Outbound,
    campaign: '',
    content: 'direct-email-v0',
    viewModel: {
      introduction: 'Welcome to Athena! As a VIP startup founder, you get to skip the waitlist, so expect to hear from us right away. We\'re pumped for the opportunity to work with you.'
    }
  }],
  [LeadSourceURLSearchParamValue.YCombinator, {
    source: LeadSource.NonIncentivizedPartnership,
    campaign: LeadSourceCampaign.YCombinator,
    content: '',
    viewModel: {
      introduction: 'Welcome to Athena! Our EAs are the hidden superpower behind dozens of YC founders, so you\'re in good company. We\'re pumped for the opportunity to work with you.',
      contentOptions: [
        'Bookface',
        'Word of mouth (YC founder)',
        'Word of mouth (another founder/exec)',
        'Eric Jorgenson\'s podcast with Balaji',
        'Sam Corcos\'s blog posts about Athena',
        'Other'
      ]
    }
  }]
])