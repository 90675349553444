import React from 'react'
import { Link } from 'react-router-dom'
import { Logo } from '@athena-labs/web-component-library'

import { Path } from '../../AppRoutes'

import './styles.css'

const Welcome: React.FC = () => {
  return (
    <div className='Welcome'>
      <figure className='Welcome_logo'>
        <Logo color='white' />
      </figure>
      <div className='Welcome_content'>
        <h1 className='Welcome_headingText'>What can we help you do?</h1>
        <div className='Welcome_buttons'>
          <Link to={Path.Root} className='Welcome_button Button Button___rounded'>Get an Athena Assistant</Link>
          <a href='https://jobs.athenago.com/' className='Welcome_button Button Button___rounded'>Become an Athena Assistant</a>
        </div>
      </div>
    </div>
  )
}

export default Welcome