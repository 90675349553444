import React from 'react'
import { createContext,PropsWithChildren } from 'react'
import { makeAutoObservable } from 'mobx'
import { SignupStep } from 'types/Signup.types'

export class SignupStore {
  public currentStep = SignupStep.PersonalDetails

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentStep = (step: SignupStep) => {
    this.currentStep = step
  }

}

const SignupStoreContext = createContext<SignupStore | undefined>(undefined)

export const SignupStoreProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <SignupStoreContext.Provider value={new SignupStore()}>
      {children}
    </SignupStoreContext.Provider>
  )
}

export const useSignupStore = () => {
  const context = React.useContext(SignupStoreContext)
  if (context === undefined) {
    throw new Error('useSignupStore must be used within an SignupStoreProvider')
  }
  return context
}
