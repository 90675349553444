import { SignupPillar } from 'types/Signup.types'

export type PillarDesktopButtonProps = {
  pillar: SignupPillar
  orientation: 'left' | 'right'
  indent: boolean
}


export const PILLARS = Object.values(SignupPillar)

export const PILLAR_DESCRIPTION = new Map<SignupPillar, string>([
  [SignupPillar.Essentials, 'Optimize your home life, finances, and more.'],
  [SignupPillar.Lifestyle, 'Enrich your life through travel, the arts, and entertainment.'],
  [SignupPillar.Fulfillment, 'Learn, create, and explore more.'],

  [SignupPillar.Career, 'Fuel your passions and leave your mark on the world.'],
  [SignupPillar.Health, 'Improve your diet, sleep, and self-care routines.'],
  [SignupPillar.Relationships, 'Nourish your professional and personal relationships.']

])

export const leftPillar = [
  SignupPillar.Essentials,
  SignupPillar.Lifestyle,
  SignupPillar.Fulfillment
]

export const rightPillar = [
  SignupPillar.Career,
  SignupPillar.Health,
  SignupPillar.Relationships
]
