import React, { PropsWithChildren } from 'react'
import Cookies from 'js-cookie'
import { makeAutoObservable } from 'mobx'

const AUTH_COOKIE_NAME = 'signup_accesskey' as const

// Expire auth cookie in 4 weeks; same as the period of time it takes to consider a deal as stale.
const EXPIRATION_IN_DAYS = 28

export class AuthStore {

  constructor() {
    makeAutoObservable(this)
  }

  public set accessKey(accessKey: string | undefined) {
    if (accessKey) {
      Cookies.set(AUTH_COOKIE_NAME, accessKey, {
        expires: EXPIRATION_IN_DAYS,
        secure: true
      })
    } else {
      Cookies.remove(AUTH_COOKIE_NAME)
    }
  }

  public get accessKey() {
    return Cookies.get(AUTH_COOKIE_NAME)
  }
}

const AuthContext = React.createContext<AuthStore | undefined>(undefined)

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <AuthContext.Provider value={new AuthStore()}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthStore = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthStore must be used within an AuthProvider')
  }
  return context
}