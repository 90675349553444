import React from 'react'
import { Logo, useMediaQuery } from '@athena-labs/web-component-library'
import { observer } from 'mobx-react-lite'
import { useSignupStore } from 'stores/SignupStore'
import { SignupStep } from 'types/Signup.types'

import styles from './SignupHeroBanner.module.css'

type StepHeroConfig = {
  coverImage: string
  logoColor: 'white' | 'black'
}

const STEP_HERO_COVER = new Map<SignupStep, StepHeroConfig>([
  [SignupStep.PersonalDetails, {
    coverImage: 'cover-start-now.jpg',
    logoColor: 'black'
  }],
  [SignupStep.ContactDetails, {
    coverImage: 'cover-contact-details.jpg',
    logoColor: 'white'
  }],
  [SignupStep.JoinWaitlist, {
    coverImage: 'cover-join-waitlist.jpg',
    logoColor: 'white'
  }],
  [SignupStep.GettingStarted, {
    coverImage: 'cover-pillars-overview.jpg',
    logoColor: 'black'
  }],
  [SignupStep.Commitments, {
    coverImage: 'cover-commitments.jpg',
    logoColor: 'black'
  }],
  [SignupStep.Waitlist, {
    coverImage: 'cover-waiting.jpg',
    logoColor: 'white'
  }],
  [SignupStep.WaitingTwoWayFit, {
    coverImage: 'cover-waiting.jpg',
    logoColor: 'white'
  }]
])

const SignupHeroBanner: React.FC = observer(() => {
  const { isDesktop } = useMediaQuery({ updateOnResize: true })
  const coverLogoType = isDesktop ? 'icon' : 'athena'

  const { currentStep: step } = useSignupStore()

  const defaultCoverConfig = STEP_HERO_COVER.get(SignupStep.PersonalDetails)!
  const coverConfig = STEP_HERO_COVER.get(step) || defaultCoverConfig

  const backgroundImage = coverConfig.coverImage

  const logoColor = coverConfig.logoColor

  return (
    <aside
      className={[
        styles.SignupHeroCover
      ].join(' ')}
      style={{
        backgroundImage: `url("/${backgroundImage}")`
      }}>
      <figure className={styles.SignupHeroCover_logo}>
        <Logo color={logoColor} type={coverLogoType} />
      </figure>
    </aside>
  )
})

export default SignupHeroBanner