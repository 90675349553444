import { z } from 'zod'

export enum SignupPath {
  Root = '/',
  PersonalDetails = '/start-now',
  ContactDetails = '/contact-details',
  JoinWaitlist = '/join-waitlist',
  GettingStarted = '/getting-started',
  Commitments = '/commitments',
  Waitlist = '/waitlist',
  WaitingTwoWayFit = '/waiting-two-way-fit'
}

export enum SignupPillar {
  Lifestyle = 'Lifestyle',
  Fulfillment = 'Fulfillment',
  Relationships = 'Relationships',
  Career = 'Career',
  Health = 'Health',
  Essentials = 'Essentials'
}

export interface StepConfig {
  path: SignupPath
  description?: string
}

export enum SignupStep {
  None = 'none',
  PersonalDetails = 'personalDetails',
  ContactDetails = 'contactDetails',
  JoinWaitlist = 'joinWaitlist',
  GettingStarted = 'gettingStarted',
  Commitments = 'commitments',
  WaitingTwoWayFit = 'waitingTwoWayFit',
  Waitlist = 'waitlist',
}

export const SignupSchema = z.object({
  id: z.string(),
  dealId: z.string(),
  step: z.nativeEnum(SignupStep),
  journey: z.string(),
  createdTime: z.date(),
  lastUpdateTime: z.date()
})

export type SignupRecord = z.infer<typeof SignupSchema>

// POST signup

// Request Schemas
export const SignupPostRequestSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  journey: z.string()
})
export type SignupPostRequestParams = z.infer<typeof SignupPostRequestSchema>

// Response Schema


export type SignupPostResponseSchema = {
  signup: SignupRecord
}

export type PostSignupSchema = {
  accessKey: string
  signup: SignupRecord
}

// PATCH signup

export const ContactDetailsPayloadSchema = z.object({
  // Contact Details Screen
  phoneNumber: z.string(),
  companyName: z.string()
})
export const ContactDetailsRequestSchema = z.object({
  step: z.literal(SignupStep.ContactDetails),
  accessKey: z.string(),
  payload: ContactDetailsPayloadSchema
})

// TODO: Make leadSourceContent required when leadSource is Referral.
export const JoinWaitlistPayloadSchema = z.object({
  leadSource: z.string(),
  leadSourceCampaign: z.string().optional(),
  leadSourceContent: z.string().optional()
})
export const JoinWaitlistRequestSchema = z.object({
  step: z.literal(SignupStep.JoinWaitlist),
  accessKey: z.string(),
  payload: JoinWaitlistPayloadSchema
})

export const SignupPatchRequestSchema = z.union([
  ContactDetailsRequestSchema,
  JoinWaitlistRequestSchema,
  // Default schema
  z.object({
    step: z.nativeEnum(SignupStep),
    accessKey: z.string(),
    payload: z.object({}).optional()
  })
])
export type SignupPatchRequestParams = z.infer<typeof SignupPatchRequestSchema>


