import React, { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { AnimatePresence } from 'framer-motion'

import styles from './SignupTransition.module.css'

const SignupTransition: React.FC<PropsWithChildren> = ({
  children
}) => {
  const location = useLocation()

  const SLIDE_RIGHT_ANIMATION = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 }
  }

  const transition = { duration: 0.3 }

  return (
    <AnimatePresence key={location.pathname}>
      <motion.div
        className={styles.SignupTransition_wrapper}
        variants={SLIDE_RIGHT_ANIMATION}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={transition}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}


export default SignupTransition