import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@athena-labs/web-component-library'
import { Analytics } from 'adapters/Analytics'
import { LOCAL_STEP_KEY } from 'hooks/useSignup'
import PillarsDesktop from 'pages/Signup/PillarsOverview/components/PillarsOverview/PillarsDesktop'
import PillarsMobile from 'pages/Signup/PillarsOverview/components/PillarsOverview/PillarsMobile'
import { useLeadSourceStore } from 'stores/LeadSource'
import { SignupStep } from 'types/Signup.types'
import { getSignupPath } from 'utils/signup'

import SubmitButton from '../components/SubmitButton'

import './styles.css'

const PillarsOverview: React.FC = () => {

  const navigate = useNavigate()
  const { journey } = useLeadSourceStore()

  const {
    breakpoints,
    currentBreakpoint
  } = useMediaQuery({ updateOnResize: true })

  const isDesktop = breakpoints[currentBreakpoint] >= breakpoints['xl']

  const onSubmit = async () => {
    // All events related to signup updates are being tracked using the useSignup hook.
    // Additionally, since some screens manage their state or effects locally (e.g., Getting Started (PillarsOverview)),
    // we also need to ensure that relevant events on those screens are tracked.
    Analytics.track('Signup updated',{
      step: SignupStep.GettingStarted,
      journey
    })
    localStorage.removeItem(LOCAL_STEP_KEY)
    navigate(getSignupPath(SignupStep.Commitments))
  }

  return (
    <>
      <section className='PillarsOverview _section'>
        <h1 className='_textHeading'>Six Pillars</h1>
        <p className='_textSubheading'>
          Your EA supports you across all pillars of your life to give you more time
          and energy back to spend on your personal and professional aspirations.
        </p>
        <div className='_fieldContainer'>
          {isDesktop ? <PillarsDesktop /> : <PillarsMobile />}
        </div>
        <SubmitButton onSubmit={onSubmit} />

      </section>
    </>
  )
}

export default PillarsOverview