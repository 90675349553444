import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Commitments from 'pages/Signup/Commitments'
import ContactDetailsForm from 'pages/Signup/ContactDetailsForm'
import PersonalDetailsForm from 'pages/Signup/PersonalDetailsForm'
import SignupForm from 'pages/Signup/SignupForm'
import WaitingTwoWayFit from 'pages/Signup/WaitingTwoWayFit'
import Waitlist from 'pages/Signup/Waitlist'
import { SignupPath } from 'types/Signup.types'

import ErrorNotFound from './pages/ErrorNotFound'
import Signup from './pages/Signup'
import PillarsOverview from './pages/Signup/PillarsOverview'
import SignupLegacy from './pages/SignupLegacy'
import Welcome from './pages/Welcome'

export enum Path {
  Welcome = '/welcome',
  SignupLegacy = '/signup',
  NotFound = '/not-found',
  Root = '/'
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={SignupPath.Root} element={<Signup />}>
        <Route path={SignupPath.PersonalDetails} element={<PersonalDetailsForm />} />
        <Route path={SignupPath.ContactDetails} element={<ContactDetailsForm />} />
        <Route path={SignupPath.Commitments} element={<Commitments />} />
        <Route path={SignupPath.JoinWaitlist} element={<SignupForm />} />
        <Route path={SignupPath.GettingStarted} element={<PillarsOverview />} />
        <Route path={SignupPath.Waitlist} element={<Waitlist />} />
        <Route path={SignupPath.WaitingTwoWayFit} element={<WaitingTwoWayFit />} />
      </Route>
      <Route path={Path.SignupLegacy} element={<SignupLegacy />} />
      <Route path={Path.Welcome} element={<Welcome />} />
      <Route
        path='*'
        element={<ErrorNotFound />} />
    </Routes>
  )
}

export default AppRoutes