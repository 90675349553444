import React, { PropsWithChildren } from 'react'
import { Footer, Logo } from '@athena-labs/web-component-library'

import Package from '../../../../package.json'

import SignupHeroBanner from './SignupHeroBanner'

import styles from './SignupLayout.module.css'

const SignupLayout: React.FC<PropsWithChildren> = ({ children }) => {

  return (
    <div className={styles.SignupLayout}>
      <SignupHeroBanner />
      <main className={styles.SignupLayout_page}>
        <figure className={styles.SignupLayout_pageLogo}>
          <Logo color='white' />
        </figure>
        <div className={styles.SignupLayout_pageContent}>
          {children}
        </div>
        <Footer version={`${Package.version}`} />
      </main>
    </div>
  )
}

export default SignupLayout