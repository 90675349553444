import React from 'react'

import Hexagon from 'components/Hexagon'

import { leftPillar, PILLAR_DESCRIPTION, PillarDesktopButtonProps, rightPillar } from '../PillarsOverview/PillarsOverview.types'

import styles from './PillarsDesktop.module.css'

const PillarDesktopButton: React.FC<PillarDesktopButtonProps> =
  ({ pillar, orientation, indent }) => {
    const getPillarStyle = () => {

      if (orientation === 'left' && indent) {
        return styles.PillarDesktop_leftButton___indented
      }
      if (orientation === 'right' && indent) {
        return styles.PillarDesktop_rightButton___indented
      }
      return styles.PillarDesktop_button
    }

    return (
      <div className={getPillarStyle()} >
        <div className={styles.PillarContent}>
          <h1 className={styles.PillarDesktop_buttonHeading}>
            {pillar}
          </h1>
          <p className={styles.PillarDesktop_buttonSubheading}>
            {PILLAR_DESCRIPTION.get(pillar)}
          </p>
        </div>
      </div>
    )
  }

const PillarsDesktop = () => {
  // Ensure that on desktop view, the first row and the last row are indented close to the hexagon
  const isIndented = (index: number): boolean => [0, 2].includes(index)
  return (
    <div className={styles.PillarsOverview_body}>
      <div className={styles.PillarsLeft}>
        {leftPillar.map((pillar, index) =>
          (<PillarDesktopButton
            orientation='left'
            indent={isIndented(index)}
            pillar={pillar}
            key={pillar} />))}
      </div>
      <Hexagon />
      <div className={styles.PillarsRight}>
        {rightPillar.map((pillar, index) =>
          (<PillarDesktopButton
            orientation='right'
            indent={isIndented(index)}
            pillar={pillar}
            key={pillar} />))}
      </div>
    </div>
  )
}

export default PillarsDesktop