import React, { FC, ReactElement, ReactNode } from 'react'

import Tooltip from './Tooltip'

interface FieldWrapperProps {
  id: string
  label: string
  error?: string
  description?: string
  tip?: ReactElement | string
  touched?: boolean
  hidden?: boolean
  children: ReactNode
}


// TODO: Move this to the WCL.
const FieldWrapper: FC<FieldWrapperProps> = (
  { id,
    label,
    touched,
    error,
    description,
    tip,
    hidden,
    children
  }) => {

  if (hidden) {
    return null
  }

  return (
    <div className='mt-2'>
      <div className='flex justify-between'>
        <label className='inline-block mb-0.5 normal-case' htmlFor={id}>{label} </label>
        {tip && <Tooltip content={tip} />}
      </div>

      <small className='block mb-1 text-shade-400'>{description}</small>
      <div>
        {children}
      </div>
      <p className={`text-accent-orange text-sm mt-1 ${touched ? 'visible' : 'invisible'}`}>{error}&nbsp;</p>
    </div>
  )
}

export default FieldWrapper