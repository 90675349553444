export enum LeadSource {
  BlogPost = 'Blog Post',
  Conference = 'Conference',
  Google = 'Google',
  IncentivizedPartnership = 'Partnership',
  NonIncentivizedPartnership = 'Non-Incentivized Partnership',
  Other = 'Other',
  Outbound = 'Outbound',
  Podcast = 'Podcast',
  Referral = 'Referral',
  Social = 'Social',
  Website = 'Website'
}

export enum LeadSourceCampaign {
  ReferralV0 = 'Referral v0',
  ReferralV1 = 'Referral v1',
  ReferralV2 = 'Referral v2',
  ReferralV3 = 'Referral v3',
  ReferralV4 = 'Referral v4',
  InternalReferral = 'Internal Referral',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  LinkedIn = 'LinkedIn',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Mylance = 'Mylance',
  YCombinator = 'Y Combinator',
  Rewardful = 'Rewardful',
  Newsletter = 'Newsletter',
  AdWords = 'AdWords'
}

// Note: Define the valid `/?source` URL search values here.
export enum LeadSourceURLSearchParamValue {
  AdWords = 'adwords',
  Community = 'community',
  JonathanNetwork = 'jonathan-vip',
  JustinMares = 'justin-mares',
  Mylance = 'mylance',
  Newsletter = 'newsletter',
  Partner = 'partner',
  Referral = 'referral',
  Rewardful = 'rewardful',
  StartupVip = 'startup-vip',
  YCombinator = 'yc'
}

export type LeadSourceURLSearchParams = URLSearchParams & {
  code: string
  journey: string
  source: LeadSourceURLSearchParamValue
  utm_source?: string
  utm_campaign?: string
  utm_content?: string
}

export interface LeadSourceProps {
  source: LeadSource
  campaign: LeadSourceCampaign | ''
  content: string
  // TODO: Update the view model property to support multiple screens.
  viewModel?: LeadSourceViewModel
}

// TODO: Create view models for each signup screen.
export interface LeadSourceViewModel {
  introduction?: string
  contentOptions?: Array<string>
}