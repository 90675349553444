import React, { FC } from 'react'
import { Button, Context, Icon, IconType } from '@athena-labs/web-component-library'

import './Banner.css'

export interface BannerProps {
  callToAction: string
  icon: IconType
  buttonText: string
  onClick: () => void
  external?: boolean
}

const Banner: FC<BannerProps> = ({
  callToAction,
  icon,
  buttonText,
  onClick,
  external
}) => {
  return (
    <div className='Banner'>
      <div className='Banner_description'>
        <div className='Banner_icon'>
          <Icon type={icon} context={Context.Secondary} />
        </div>
        <p>{callToAction}</p>
      </div>
      <div className='Banner_ctaButton'>
        <Button onClick={onClick} icon={external ? 'redirect' : undefined}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
export default Banner
