import { SignupPath, SignupStep, StepConfig } from 'types/Signup.types'

export const SIGNUP_STEP_CONFIG_MAP = new Map<SignupStep, StepConfig>([
  [SignupStep.PersonalDetails, {
    path: SignupPath.PersonalDetails
  }],
  [SignupStep.ContactDetails, {
    path: SignupPath.ContactDetails
  }],
  [SignupStep.JoinWaitlist, {
    path: SignupPath.JoinWaitlist
  }],
  [SignupStep.Commitments, {
    path: SignupPath.Commitments
  }],
  [SignupStep.GettingStarted, {
    path: SignupPath.GettingStarted
  }],
  [SignupStep.Waitlist, {
    path: SignupPath.Waitlist
  }],
  [SignupStep.WaitingTwoWayFit, {
    path: SignupPath.WaitingTwoWayFit
  }]
])
