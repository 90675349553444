import React from 'react'
import { Context, Icon, IconType } from '@athena-labs/web-component-library'
import { SignupStep } from 'types/Signup.types'
import { WaitingMetadata, WaitingProps } from 'types/Waiting.types'

import './styles.css'

export const WAITING_METADATA_MAP = new Map<SignupStep, WaitingMetadata>([
  [SignupStep.Waitlist, {
    title: 'You\'re on the waitlist.',
    intro: 'Waits vary from days to a few weeks. To speed things up, let\'s make sure there\'s a two-way fit.',
    icon: IconType.ConfirmedCall
  }],
  [SignupStep.WaitingTwoWayFit, {
    title: 'Two-Way Fit Call Booked',
    intro: 'Can\'t wait to connect. This time is for clarifying the process, your needs, and what you want to accomplish with Athena. Come with any questions.',
    icon: IconType.ConfirmedCall
  }]
])

const WAITLIST_REFERRAL_COPY: WaitingMetadata = {
  title: 'You\'ve got VIP status.',
  intro: 'As a referred client, you get to skip the waitlist. Check your email to book a call with one of our team members where you\'ll discuss your goals and what we offer. If we\'re lost in your inbox, check your promotions folder (but just think, your EA can do that soon).',
  icon: IconType.ConfirmedCall
}

const Waiting: React.FC<WaitingProps> = ({
  step = SignupStep.None,
  referred = false,
  children
}) => {

  const waitingMetadata = WAITING_METADATA_MAP.get(step) as WaitingMetadata
  const { title, intro, icon } = referred ? WAITLIST_REFERRAL_COPY : waitingMetadata

  return (
    <>
      <section className='Waiting _section' data-testid='Waiting'>
        <div className='Waiting_content'>
          <div className='Waiting_icon'>
            <Icon type={icon} context={Context.Active} />
          </div>
          <div className='Waiting_heading'>
            <h1 className='Waiting_headingText'>
              {title}
            </h1>
            <p className='_subheadingText'>
              {intro}
            </p>
          </div>
        </div>
        {children && (
          <div>
            {children}
          </div>
        )}
      </section>
    </>
  )
}

export default Waiting