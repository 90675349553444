import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import {
  Context,
  FormField,
  getFieldStatus,
  Icon,
  IconType,
  Input,
  PhoneNumberField
} from '@athena-labs/web-component-library'
import { PhoneNumber } from '@athena-labs/web-component-library/dist/types/types/PhoneNumberField.types'
import { ACCESS_KEY } from 'adapters/API.v2'
import { Field, FieldProps, Formik } from 'formik'
import { useSignup } from 'hooks/useSignup'
import { observer } from 'mobx-react-lite'
import { ContactDetailsPayloadSchema, SignupStep } from 'types/Signup.types'
import { trackFieldValue } from 'utils/input'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import SubmitButton from '../components/SubmitButton'

import './styles.css'

const ContactDetailsForm: React.FC = observer(() => {

  // The phone number field has a default country code of US
  const [phoneExtension, setPhoneExtension] = useState('1')

  const { updateSignup } = useSignup()
  const [cookies] = useCookies([ACCESS_KEY])
  const { signup_accesskey } = cookies

  // TODO: Move this to WLC
  const getStatus = (touched: boolean, error: string | undefined, value: unknown = null) => {
    if (touched && !error && value) {
      return 'success'
    }
    if (value && !error) {
      return 'success'
    }
    return getFieldStatus(touched, error)
  }

  const onSubmit = async (data: z.infer<typeof ContactDetailsPayloadSchema>) => {
    await updateSignup({
      step: SignupStep.ContactDetails,
      accessKey: signup_accesskey,
      payload: ContactDetailsPayloadSchema.parse({
        ...data,
        phoneNumber: `+${phoneExtension}${data.phoneNumber}`
      })
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          phoneNumber: '',
          companyName: ''
        }}
        onSubmit={onSubmit}
        validationSchema={toFormikValidationSchema(ContactDetailsPayloadSchema)}
      >
        {({ isSubmitting, errors, submitForm, setFieldValue }) => {

          const showPhoneNumberWarning =
            phoneExtension.indexOf('63') === 0 ||
            phoneExtension.indexOf('234') === 0

          return (
            <section className='ContactDetailsForm _section'>
              <h1 className='_textHeading'>Start Now</h1>
              <p className='_textSubheading'>
                Give us a few more details to get things going.
              </p>
              <div className='_fieldContainer'>
                <Field name='companyName'>
                  {({ field, meta }: FieldProps) => (
                    <FormField
                      id='companyName'
                      label='Company Name'
                      touched={meta.touched}
                      errorHint={meta.error}
                    >
                      <Input
                        {...field}
                        status={getStatus(meta.touched, meta.error, field.value)}
                        id='companyName'
                        type='companyName'
                        onBlur={trackFieldValue(field.name)}
                      />
                    </FormField>
                  )}
                </Field>
                <Field name='phoneNumber'>
                  {({ field, meta }: FieldProps) => (
                    <FormField
                      id='phoneNumber'
                      label='Phone number'
                      touched={meta.touched}
                      errorHint={meta.error}
                    >
                      {/* TODO: Fixed behavior of Phone Number Field on WCL */}
                      <PhoneNumberField
                        {...field}
                        id='phoneNumber'
                        status={getStatus(meta.touched, meta.error, field.value)}
                        value={field.value}
                        onBlur={trackFieldValue(field.name)}

                        // We need to use setFieldValue as PhoneNumberInput
                        // doesn't play nicely with Formik
                        onPhoneNumberChange={(value: PhoneNumber) => {
                          setPhoneExtension(value.extension)
                          return setFieldValue('phoneNumber', value.phoneNumber)
                        }}
                      />
                    </FormField>
                  )}
                </Field>
                {
                  showPhoneNumberWarning && (
                    <div className='EAWarning'>
                      <Icon context={Context.Primary} type={IconType.Alert} />
                      <p className='EAWarning_label'>
                        Are you trying to become an Executive Assistant?&nbsp;
                        <a href='https://jobs.athenago.com' className='EAWarning_redirectLink'>
                          Apply here.
                        </a>
                      </p>
                    </div>
                  )
                }

              </div>
              <SubmitButton
                onSubmit={submitForm}
                loading={isSubmitting}
                disabled={isSubmitting || Boolean(Object.keys(errors).length)}
              />
            </section >
          )
        }}
      </Formik>
    </>
  )
})

export default ContactDetailsForm