import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { LoadingCover, Toast } from '@athena-labs/web-component-library'
import { ACCESS_KEY } from 'adapters/API.v2'
import { Path } from 'AppRoutes'
import { LOCAL_STEP_KEY, useSignup } from 'hooks/useSignup'
import { observer } from 'mobx-react-lite'
import { useSignupStore } from 'stores/SignupStore'
import { SignupPath, SignupStep } from 'types/Signup.types'
import { getSignupPath } from 'utils/signup'

import SignupLayout from './components/SignupLayout'
import SignupTransition from './components/SignupTransition'

const V4_USER_PERCENTAGE = Number(process.env.REACT_APP_V4_USER_PERCENT)

const Signup: React.FC = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(window.location.search)


  const [accessKey, setAccessKey] = useState('')
  const [cookies] = useCookies([ACCESS_KEY])

  const { signup, isLoading } = useSignup(accessKey)
  const { setCurrentStep } = useSignupStore()

  useEffect(() => {
    // TODO: Remove this effect once we make the legacy signup form obsolete.
    // Root route effect that redirects 10% of users to the Signup v4 flow.
    if (location.pathname === Path.Root && Math.random() > V4_USER_PERCENTAGE) {
      navigate({
        pathname: Path.SignupLegacy,
        search: searchParams.toString()
      })
      return
    }

    if (cookies.signup_accesskey) {
      setAccessKey(cookies.signup_accesskey)
    } else {
      navigate({
        pathname: SignupPath.PersonalDetails,
        search: searchParams.toString()
      })
    }
  }, [])

  useEffect(() => {
    const step = signup?.step
    if (step) {
      let currentStep = step

      // This is a check to whether to show the Getting Started screen
      // See the notes on the patch request. for more details
      const localStep = localStorage.getItem(LOCAL_STEP_KEY)
      if (localStep === SignupStep.GettingStarted) {
        currentStep = SignupStep.GettingStarted
      }

      setCurrentStep(currentStep)
      navigate(getSignupPath(currentStep))
    }
  }, [signup])

  if (isLoading) {
    return <LoadingCover />
  }

  return (
    <SignupLayout>
      <Toast />
      <SignupTransition>
        <Outlet />
      </SignupTransition>
    </SignupLayout>
  )
})

export default Signup