import React from 'react'
import { Context, FormField, getFieldStatus, Icon, IconType, Input } from '@athena-labs/web-component-library'
import { Field, FieldProps, Formik } from 'formik'
import { useSignup } from 'hooks/useSignup'
import { observer } from 'mobx-react-lite'
import { useLeadSourceStore } from 'stores/LeadSource'
import { isPersonalEmail } from 'utils/email'
import { trackFieldValue } from 'utils/input'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import SubmitButton from '../components/SubmitButton'

import './styles.css'

const PersonalDetailsForm: React.FC = observer(() => {

  const { createSignup, isMutating } = useSignup()
  const { journey } = useLeadSourceStore()

  const formSchema = z.object({
    firstName: z.string({ required_error: 'First name is required' }),
    lastName: z.string({ required_error: 'Last name is required' }),
    email: z.string({ required_error: 'Email is required' }).email('Email must be valid')
  })

  // TODO: Move this to WLC
  const getStatus = (touched: boolean, error: string | undefined, value: unknown = null) => {
    if (touched && !error && value) {
      return 'success'
    }
    if (value && !error) {
      return 'success'
    }
    return getFieldStatus(touched, error)
  }

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    await createSignup({ ...data, journey })
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: ''
        }}
        onSubmit={onSubmit}
        validationSchema={toFormikValidationSchema(formSchema)}
      >
        {({ errors, submitForm, values }) => {

          return (
            <section className='_section'>
              <h1 className='_textHeading'>Start Now</h1>
              <p className='_textSubheading'>
                Tell us a little about yourself.&nbsp;
                <span className='text-shade-300'>Already have an account?</span>&nbsp;
                <a className='text-accent-blue no-underline' href={`${process.env.REACT_APP_PORTAL_URL}/?signup`}>Log in</a>
              </p>
              <div className='_fieldContainer'>
                <div className='lg:grid lg:grid-rows-1 lg:grid-cols-2 lg:gap-4'>
                  <Field name='firstName'>
                    {({ field, meta }: FieldProps) => (
                      <FormField
                        id='firstName'
                        label='First name'
                        touched={meta.touched}
                        errorHint={meta.error}
                      >
                        <Input
                          {...field}
                          onBlur={trackFieldValue(field.name)}
                          status={getStatus(meta.touched, meta.error, field.value)}
                          id='firstName'
                          type='text' />
                      </FormField>
                    )}
                  </Field>

                  <Field name='lastName'>
                    {({ field, meta }: FieldProps) => (
                      <FormField
                        id='lastName'
                        label='Last name'
                        touched={meta.touched}
                        errorHint={meta.error}
                      >
                        <Input
                          {...field}
                          status={getStatus(meta.touched, meta.error, field.value)}
                          id='lastName'
                          type='text'
                          onBlur={trackFieldValue(field.name)}
                        />
                      </FormField>
                    )}
                  </Field>
                </div>

                <Field name='email'>
                  {({ field, meta }: FieldProps) => (
                    <FormField
                      id='email'
                      label='Email'
                      touched={meta.touched}
                      errorHint={meta.error}
                    >
                      <Input
                        {...field}
                        status={getStatus(meta.touched, meta.error, field.value)}
                        id='email'
                        type='email'
                        onBlur={trackFieldValue(field.name)}
                      />
                    </FormField>
                  )}
                </Field>
                {isPersonalEmail(values.email) && (
                  <div className='EAWarning'>
                    <Icon context={Context.Primary} type={IconType.Alert} />

                    <p className='EAWarning_label'>
                    Are you trying to become an Executive Assistant?&nbsp;
                      <a href='https://jobs.athenago.com' className='EAWarning_redirectLink'>
                      Apply here.
                      </a>
                    </p>
                  </div>
                )}
              </div>
              <div className='PersonalDetailsForm_submitButton'>
                <SubmitButton
                  onSubmit={submitForm}
                  loading={isMutating}
                  disabled={isMutating || Boolean(Object.keys(errors).length)}
                />
                <div className='PersonalDetailsForm_notice'>
                  <p className='EAWarning_label'>
                    Interested in becoming an Executive Assistant?&nbsp;
                    <a className='text-accent-blue no-underline' href='https://jobs.athenago.com'>
                      Apply here.
                    </a>
                  </p>
                </div>
              </div>
            </section >
          )
        }}
      </Formik>
    </>
  )
})

export default PersonalDetailsForm