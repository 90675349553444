export interface PrefilledSource {
  label: string
  introduction?: string
  additionalSourceOptions?: Array<string>
  code?: string
}

// These values must match the source values in HubSpot
export enum LeadSource {
  BlogPost = 'Blog Post',
  Google = 'Google',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Mylance = 'Mylance',
  Other = 'Other',
  Outbound = 'Outbound',
  IncentivizedPartnership = 'Partnership',
  NonIncentivizedPartnership = 'Non-Incentivized Partnership',
  Podcast = 'Podcast',
  Referral = 'Referral',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  Website = 'Website',
  YCombinator = 'Y Combinator',
  Rewardful = 'Rewardful',
  Newsletter = 'Newsletter',
  AdWords = 'AdWords',
  Conference = 'Conference'
}