
import React, { useState } from 'react'
import { SignupPillar } from 'types/Signup.types'

import Hexagon from 'components/Hexagon'

import { PILLAR_DESCRIPTION } from '../PillarsOverview/PillarsOverview.types'

import styles from './PillarsMobile.module.css'

const PILLARS_IN_ORDER = [
  SignupPillar.Essentials,
  SignupPillar.Career,
  SignupPillar.Lifestyle,
  SignupPillar.Health,
  SignupPillar.Fulfillment,
  SignupPillar.Relationships
]

const PillarsMobile = () => {

  const [selectedPillar, setSelectedPillar] = useState(SignupPillar.Essentials)


  return (
    <div className={`PillarsMobile ${styles.PillarsMobile}`}>
      <Hexagon />
      <div className={styles.PillarsMobile_buttons}>
        {PILLARS_IN_ORDER.map((pillar) => (
          <button
            key={pillar}
            className={[
              styles.PillarsMobile_button,
              pillar === selectedPillar ? styles.PillarsMobile_button___active : ''
            ].join(' ')}
            onClick={() => setSelectedPillar(pillar)}>
            {pillar}
          </button>
        ))}
      </div>
      <p className={styles.PillarMobile_description}>
        {PILLAR_DESCRIPTION.get(selectedPillar)}
      </p>
    </div>
  )
}

export default PillarsMobile
