import React, { useEffect } from 'react'
import { Button, IconLegacy } from '@athena-labs/web-component-library'
import { SubmitButtonProps } from 'types/SubmitButton.types'

import styles from './SubmitButton.module.css'

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onSubmit,
  disabled,
  loading
}) => {
  useEffect(() => {
    const keyboardEvent = async (event: KeyboardEvent) => {
      if (event.key === 'Enter' && onSubmit) {
        await onSubmit()
      }
    }

    window.addEventListener('keydown', keyboardEvent)

    return () => {
      window.removeEventListener('keydown', keyboardEvent)
    }
  }, [])

  const onClick = async () => {
    if (onSubmit) {
      await onSubmit()
    }
  }

  return (
    <div className={styles.SubmitButton_container}>
      <p className={styles.SubmitButton_buttonText}>
        <span>press</span>
        <strong className={styles.SubmitButton_buttonTextHighlight}>
          Enter
        </strong>
      </p>
      <div className={styles.SubmitButton_button}>
        <div className={styles.SubmitButton_iconContainer}>
          <IconLegacy color='secondary' type='enter-arrow' />
        </div>
        <Button
          disabled={disabled}
          loading={loading}
          onClick={onClick}
          type='submit'
          color='primary'
        >
          Continue
        </Button>
      </div>
    </div>
  )
}

export default SubmitButton
