import { hotjar } from 'react-hotjar'
import * as Sentry from '@sentry/react'
import mixpanel, { Dict } from 'mixpanel-browser'

const env_check = process.env.NODE_ENV === 'production'
const {
  REACT_APP_MIXPANEL_ID,
  REACT_APP_HOTJAR_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT
} = process.env


const actions = {
  init: () => {
    if (env_check) {
      REACT_APP_SENTRY_ENVIRONMENT && REACT_APP_SENTRY_DSN &&
        Sentry.init({
          dsn: process.env.REACT_APP_SENTRY_DSN,
          environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
          integrations: [new Sentry.BrowserTracing()],
          tracesSampleRate: 1.0
        })
      REACT_APP_MIXPANEL_ID && mixpanel.init(REACT_APP_MIXPANEL_ID)
      REACT_APP_HOTJAR_ID && hotjar.initialize(parseInt(REACT_APP_HOTJAR_ID), 6)
    }
  },
  identify: (id?: string) => {
    if (env_check && id) {
      REACT_APP_MIXPANEL_ID && mixpanel.identify(id)
      REACT_APP_HOTJAR_ID && hotjar.identify(id, {})
      REACT_APP_SENTRY_DSN && Sentry.setUser({ email: id })
    }
  },
  alias: (id: string) => {
    if (env_check) {
      REACT_APP_MIXPANEL_ID && mixpanel.alias(id)
    }
  },
  track: (name: string, props?: Dict) => {
    if (env_check) {
      REACT_APP_MIXPANEL_ID && mixpanel.track(name, props)
      REACT_APP_HOTJAR_ID && hotjar.event(name)
    }
  },
  people: {
    set: (props: Dict) => {
      if (env_check) {
        REACT_APP_MIXPANEL_ID && mixpanel.people.set(props)
      }
    }
  }
}

export const Analytics = actions