import axios, { AxiosResponse } from 'axios'
import {
  PostSignupSchema,
  SignupPatchRequestParams,
  SignupPostRequestParams,
  SignupPostRequestSchema,
  SignupPostResponseSchema,
  SignupRecord
} from 'types/Signup.types'

const AUTH_HEADER_PREFIX = 'x-portal-accesskey'
export const ACCESS_KEY = 'signup_accesskey'

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`
})

export const postSignup = async (
  params: SignupPostRequestParams
): Promise<PostSignupSchema> => {
  const requestBody = SignupPostRequestSchema.parse(params)
  const response = await API.post<
    SignupPostRequestParams, AxiosResponse<SignupPostResponseSchema>>(
      '/signup',
      requestBody
    )

  // Transform response from API so that useSignup will have access to the access key
  return {
    accessKey: response.headers[AUTH_HEADER_PREFIX],
    signup: response.data.signup
  }
}

export const getSignup = async (
  accessKey: string
): Promise<SignupRecord> => {
  const headers = { ...createAuthorizationHeader(accessKey) }
  const response = await API.get<never, AxiosResponse<SignupRecord>>(
    '/signup',
    { headers }
  )
  return response.data
}

export const patchSignup = async (
  params: SignupPatchRequestParams
): Promise<SignupRecord> => {
  const { accessKey, step, payload } = params

  const body = { step, payload }
  const headers = { ...createAuthorizationHeader(accessKey) }

  const response = await API.patch<SignupRecord, AxiosResponse<SignupRecord>>(
    '/signup',
    body,
    { headers }
  )
  return response.data
}

function createAuthorizationHeader(accessKey: string) {
  return {
    [AUTH_HEADER_PREFIX]: `${accessKey}`
  }
}

export default API