import React from 'react'

const ErrorNotFound = () => {
  const supportEmailLink = `mailto:support@athenago.com?subject=Page not found in Athena Signup (${window.location.href})`
  return (
    <section className='flex-1 flex items-center max-w-4xl'>
      <div className='p-8 md:p-16'>
        <h1 className='_errorHeadingText'>Seems like you have reached a URL that does not exist.</h1>
        <p className='_errorSubheadingText'>
          If you encounter this URL from one of our emails,
          please reach out to your onboarding manager or to our support team:
        </p>
        <a
          className='block mb-12 underline underline-offset-4'
          href={supportEmailLink}>
          support@athenago.com
        </a>

      </div>
    </section>
  )
}

export default ErrorNotFound